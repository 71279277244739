<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiInterLockId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-moc 
                      :editable="editable"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="data.psiInterLockId"
                      documentTitle="facilityName"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="인터락 작동조건 및 가동중지 범위 기본정보" class="cardClassDetailForm">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiInterLockId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="psiInterLockId"
                name="selectedpsiInterLockId"
                label=""
                v-model="selectedpsiInterLockId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && popupParam.psiInterLockId && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && popupParam.psiInterLockId && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable && popupParam.psiInterLockId"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="인터락 번호"
                  name="interLockNo"
                  v-model="data.interLockNo">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="대상설비"
                  name="facilityName"
                  v-model="data.facilityName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="감지기번호"
                  name="detectorNum"
                  v-model="data.detectorNum">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="최종작동설비"
                  name="lastOperationEquipment"
                  v-model="data.lastOperationEquipment">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="가동중지범위"
                  name="operationStopRange"
                  v-model="data.operationStopRange">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="점검주기"
                  name="checkCycle"
                  v-model="data.checkCycle">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-6">
                <c-textarea
                  :editable="editable"
                  label="비고"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-3">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="설정값 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  suffix="℃"
                  :editable="editable"
                  label="온도(℃)"
                  name="temperature"
                  v-model="data.temperature">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="MPa"
                  :editable="editable"
                  label="압력(MPa)"
                  name="pressure"
                  v-model="data.pressure">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="m"
                  :editable="editable"
                  label="액위(m)"
                  name="tempOperation"
                  v-model="data.tempOperation">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="기타"
                  name="tempDesign"
                  v-model="data.tempDesign">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'inter-lock-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiInterLockId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      selectedpsiInterLockId: null,
      saveUrl: transactionConfig.psi.pcd.interlock.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        psiInterLockId: '',  // 인터락 일련번호
        plantCd: '',  // 사업장코드
        interLockNo: '',  // 인터록 번호
        facilityName: '',  // 대상설비번호
        temperature: '',  // 온도
        pressure: '',  // 압력
        liquidLevel: '',  // 액위
        etc: '',  // 기타
        detectorNum: '',  // 감지기번호
        lastOperationEquipment: '',  // 최종작동설비
        operationStopRange: '',  // 가동중지범위
        checkCycle: '',  // 점검주기
        remark: '',  // 비고
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.pcd.interlock.get.url;
      this.insertUrl = transactionConfig.psi.pcd.interlock.insert.url;
      this.updateUrl = transactionConfig.psi.pcd.interlock.update.url;
      this.deleteUrl = transactionConfig.psi.pcd.interlock.delete.url;
      this.revlistUrl = selectConfig.psi.pcd.interlock.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiInterLockId) {
        this.getDetail(this.popupParam.psiInterLockId, true);
        this.selectedpsiInterLockId = this.popupParam.psiInterLockId;
      }
    },
    getDetail(_psiInterLockId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiInterLockId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiInterLockId == this.popupParam.psiInterLockId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiInterLockId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';  
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiInterLockId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiInterLockId = result.data.psiInterLockId
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.selectedpsiInterLockId = row;
        if (row != this.data.psiInterLockId) {
          this.getDetail(this.selectedpsiInterLockId, false);
        }
      }
    },
  }
};
</script>
